.entryLogo {
  padding: 16px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;

  font-family: 'Rubik Mono One';
  color: white;
  span {
    font-size: 1.5rem;
  }
}
.entryBackground {
  height: 100vh;
  min-height: 600px;
  background: linear-gradient(
    163.85deg,
    rgb(var(--gray-10)) 0%,
    rgb(var(--primary-8)) 100%
  );

  display: flex;
  flex-direction: column;

  .main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .entryBox {
    margin-top: -40px;
    width: 400px;
		height: 460px;
    .entryTitle {
      color: white;
			text-align: center;
      font-weight: bold;
    }
    .entryTypes {
      color: white;
      display: flex;
      & > div {
				cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;
        padding: 20px 0;
        text-align: center;
        &.selected {
          background: white;
          color: rgb(var(--blue-10));
        }
        & > span {
          opacity: 0.8;
        }
        & > svg {
          font-size: 40px;
        }
      }
    }
    .entryBody {
      padding: 30px 20px;
      background: white;
    }
  }
}

.register-form-wrapper{
  display: flex;
  flex-direction: column;
}
