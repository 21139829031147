.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-align: center;
  color: var(--color-text-4);
  text-decoration: none;
  font-size: 12px;
}
.footerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  a {
    color: var(--color-text-4);
  }
}
