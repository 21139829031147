
.login-form {
  &-wrapper {
    width: 320px;
  }

  &-title {
    font-size: 24px;
    font-weight: 500;
    color: var(--color-text-1);
    line-height: 32px;
  }

  &-sub-title {
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text-3);
  }

  &-error-msg {
    height: 32px;
    line-height: 32px;
    color: rgb(var(--red-6));
  }

  &-password-actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  &-register-btn {
    color: var(--color-text-3) !important;
  }
}
